import React from 'react'
import Header from '../header'

import '../../styles/layout.css'
import styles from './layout.module.css'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj } from '../../lib/helpers'

const Layout = ({
  children,
  companyInfo,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  socialLink
}) => {
  console.log(socialLink)
  return (
    <>
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <div className={styles.content}>{children}</div>
      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.socialLinks}>
            {socialLink &&
              socialLink.nodes.map(link => (
                <a href={link.link}>
                  <img
                    className={styles.socialIcon}
                    src={imageUrlFor(buildImageObj(link.icon))
                      .height(40)
                      .width(40)
                      .url()}
                  />
                </a>
              ))}
          </div>
          <div className={styles.companyAddress}>
            {companyInfo && (
              <div>
                {companyInfo.name}
                <br />
                {companyInfo.zipCode} {companyInfo.city}
                {companyInfo.country && <span>, {companyInfo.country}</span>}
              </div>
            )}
          </div>

          <div className={styles.siteInfo}>
            © {new Date().getFullYear()}, Built with <a href="https://www.sanity.io">Sanity</a>{' '}
            &amp;
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
